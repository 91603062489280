<template>
    <modal :show.sync="show" :on-close="onClose">
        <div class="column content-box">
            <woot-modal-header :header-title="pageTitle" />
            <form class="row medium-8" @submit.prevent="editAgent()">
                <div class="medium-12 columns">
                    <label :class="{ error: $v.feedbackName.$error }">
                        {{ $t('FEEDBACK_MESSAGE.EDIT.FORM.NAME.LABEL') }}
                        <input
                                v-model.trim="feedbackName"
                                type="text"
                                :placeholder="$t('FEEDBACK_MESSAGE.EDIT.FORM.NAME.PLACEHOLDER')"
                                @input="$v.feedbackName.$touch"
                        />
                    </label>
                </div>
                <div class="medium-12 columns">
                    <label :class="{ error: $v.feedbackEndpoint.$error }">
                        {{ $t('FEEDBACK_MESSAGE.EDIT.FORM.ENDPOINT.LABEL') }}
                        <input
                                v-model.trim="feedbackEndpoint"
                                type="text"
                                :placeholder="$t('FEEDBACK_MESSAGE.EDIT.FORM.ENDPOINT.PLACEHOLDER')"
                                @input="$v.feedbackEndpoint.$touch"
                        />
                    </label>
                </div>
                <div class="medium-12 columns">
                    <label>
                        {{ $t('FEEDBACK_MESSAGE.EDIT.FORM.AGENT.LABEL') }}
                        <select v-model="user">
                            <option v-for="box in agentsList" :key="box.id" :value="box.id">
                                {{ box.name }}
                            </option>
                        </select>
                    </label>
                </div>
                <div class="medium-12 modal-footer">
                    <div class="medium-6 columns" style="display: contents">
                        <woot-submit-button
                                :disabled="
                $v.feedbackName.$invalid ||
                  $v.feedbackEndpoint.$invalid ||
                  $v.user.$invalid ||
                  uiFlags.isUpdating
              "
                                :button-text="$t('FEEDBACK_MESSAGE.EDIT.FORM.SUBMIT')"
                                :loading="uiFlags.isUpdating"
                        />
                        <button class="button clear" @click.prevent="onClose">
                            {{ $t('FEEDBACK_MESSAGE.EDIT.CANCEL_BUTTON_TEXT') }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import WootSubmitButton from '../../../../components/buttons/FormSubmitButton';
import Modal from '../../../../components/Modal';

export default {
    components: {
        WootSubmitButton,
        Modal,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        endpoint: {
            type: String,
            required,
        },
        userId: {
            type: Number,
            required: true
        },
        onClose: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            feedbackName: this.name,
            feedbackEndpoint: this.endpoint,
            user: this.userId,
            vertical: 'bottom',
            horizontal: 'center',
            show: true,
        };
    },
    validations: {
        feedbackId: {
            required,
        },
        feedbackName: {
            required,
            minLength: minLength(3),
        },
        feedbackEndpoint: {
            required,
        },
        user: {
            required,
        }
    },
    computed: {
        ...mapGetters({
            uiFlags: 'agents/getUIFlags',
            agentsList: 'agents/getAgents',
        }),
        pageTitle() {
            return `${this.$t('FEEDBACK_MESSAGE.EDIT.TITLE')} - ${this.feedbackName}`;
        },
    },
    async mounted() {
        await this.$store.dispatch('agents/get');
    },
    methods: {
        showAlert(message) {
            bus.$emit('newToastMessage', message);
        },
        async editAgent() {
            try {
                await this.$store.dispatch('feedbacks/update', {
                    id: this.id,
                    name: this.feedbackName,
                    endpoint: this.feedbackEndpoint,
                    user_id: this.user,                });
                this.showAlert(this.$t('FEEDBACK_MESSAGE.EDIT.API.SUCCESS_MESSAGE'));
                this.onClose();
            } catch (error) {
                this.showAlert(this.$t('FEEDBACK_MESSAGE.EDIT.API.ERROR_MESSAGE'));
            }
        },
    },
};
</script>
