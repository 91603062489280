<template>
    <modal :show.sync="show" :on-close="onClose">
        <div class="column content-box">
            <woot-modal-header :header-title="pageTitle" />
            <form class="row medium-8" @submit.prevent="editAgent()">
                <div class="medium-12 columns">
                    <label :class="{ error: $v.iframeTitle.$error }">
                        {{ $t('IFRAME_CREATOR.EDIT.FORM.TITLE.LABEL') }}
                        <input
                                v-model.trim="iframeTitle"
                                type="text"
                                :placeholder="$t('IFRAME_CREATOR.EDIT.FORM.TITLE.PLACEHOLDER')"
                                @input="$v.iframeTitle.$touch"
                        />
                    </label>
                </div>
                <div class="medium-12 columns">
                    <label :class="{ error: $v.iframeUrl.$error }">
                        {{ $t('IFRAME_CREATOR.EDIT.FORM.URL.LABEL') }}
                        <input
                                v-model.trim="iframeUrl"
                                type="text"
                                :placeholder="$t('IFRAME_CREATOR.EDIT.FORM.URL.PLACEHOLDER')"
                                @input="$v.iframeUrl.$touch"
                        />
                    </label>
                </div>
                <div class="medium-12 columns">
                    <label :class="{ error: $v.iframeStatus.$error }">
                        {{ $t('IFRAME_CREATOR.EDIT.FORM.STATUS.LABEL') }}
                        <select v-model="iframeStatus">
                            <option v-for="option in iframeOptions" :key="option.label" :value="option.name">
                                {{ option.label }}
                            </option>
                        </select>
                        <span v-if="$v.iframeStatus.$error" class="message">
                          {{ $t('IFRAME_CREATOR.EDIT.FORM.STATUS.ERROR') }}
                        </span>
                    </label>
                </div>
                <div class="medium-12 columns">
                    <label>
                        {{ $t('IFRAME_CREATOR.ADD.FORM.ADD_INBOX_ON_IFRAME.LABEL') }}
                        <select v-model="inbox">
                            <option v-for="box in inboxesList" :key="box.id" :value="box.id">
                                {{ box.name }}
                            </option>
                        </select>
                    </label>
                </div>

                <div class="medium-12 modal-footer">
                    <div class="medium-6 columns">
                        <woot-submit-button
                                :disabled="
                $v.iframeUrl.$invalid ||
                  $v.iframeTitle.$invalid ||
                  $v.iframeStatus.$invalid ||
                  $v.inbox.$invalid ||
                  uiFlags.isUpdating
              "
                                :button-text="$t('IFRAME_CREATOR.EDIT.FORM.SUBMIT')"
                                :loading="uiFlags.isUpdating"
                        />
                        <button class="button clear" @click.prevent="onClose">
                            {{ $t('IFRAME_CREATOR.EDIT.CANCEL_BUTTON_TEXT') }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import WootSubmitButton from '../../../../components/buttons/FormSubmitButton';
import Modal from '../../../../components/Modal';

export default {
    components: {
        WootSubmitButton,
        Modal,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        url: {
            type: String,
            required,
        },
        status: {
            type: String,
            required: true,
        },
        inboxId: {
            type: Number,
            required: true
        },
        onClose: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            iframeTitle: this.title,
            iframeUrl: this.url,
            iframeStatus: this.status,
            vertical: 'bottom',
            horizontal: 'center',
            show: true,
            inbox: this.inboxId,
        };
    },
    validations: {
        iframeTitle: {
            required,
            minLength: minLength(3),
        },
        iframeUrl: {
            required,
        },
        iframeStatus: {
            required,
        },
        inbox: {
            required,
        }
    },
    computed: {
        ...mapGetters({
            uiFlags: 'agents/getUIFlags',
            inboxesList: 'inboxes/getInboxes',
        }),
        pageTitle() {
            return `${this.$t('IFRAME_CREATOR.EDIT.TITLE')} - ${this.name}`;
        },

        iframeOptions() {
            return  [
                {
                    name: 'enable',
                    label: this.$t('IFRAME_CREATOR.EDIT.FORM.STATUS.ENABLED_LABEL'),
                },
                {
                    name: 'disable',
                    label: this.$t('IFRAME_CREATOR.EDIT.FORM.STATUS.DISABLED_LABEL'),
                }
            ]
        },
    },
    methods: {
        showAlert(message) {
            bus.$emit('newToastMessage', message);
        },
        async editAgent() {
            try {
                await this.$store.dispatch('iframeCreators/update', {
                    id: this.id,
                    title: this.iframeTitle,
                    status: this.iframeStatus === 'enable',
                    url: this.iframeUrl,
                    inbox_id: this.inbox,                });
                this.showAlert(this.$t('IFRAME_CREATOR.EDIT.API.SUCCESS_MESSAGE'));
                this.onClose();
            } catch (error) {
                this.showAlert(this.$t('IFRAME_CREATOR.EDIT.API.ERROR_MESSAGE'));
            }
        },
    },
};
</script>
