<template>
  <woot-modal :show.sync="show" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header
        :header-title="$t('AGENT_MGMT.ADD.TITLE')"
        :header-content="$t('AGENT_MGMT.ADD.DESC')"
      />

      <form class="row" @submit.prevent="addAgent()">
        <div class="medium-12 columns">
          <label :class="{ error: $v.agentName.$error }">
            {{ $t('AGENT_MGMT.ADD.FORM.NAME.LABEL') }}
            <input
              v-model.trim="agentName"
              type="text"
              :placeholder="$t('AGENT_MGMT.ADD.FORM.NAME.PLACEHOLDER')"
              @input="$v.agentName.$touch"
            />
          </label>
        </div>
        <div class="medium-12 columns">
          <label :class="{ error: $v.agentType.$error }">
            {{ $t('AGENT_MGMT.ADD.FORM.AGENT_TYPE.LABEL') }}
            <select v-model="agentType">
              <option v-for="role in this.roles" :key="role.name" :value="role.name">
                {{ role.label }}
              </option>
            </select>
            <span v-if="$v.agentType.$error" class="message">
              {{ $t('AGENT_MGMT.ADD.FORM.AGENT_TYPE.ERROR') }}
            </span>
          </label>
        </div>
          <div class="medium-12 columns">
              <label>
                  {{ $t('AGENT_MGMT.ADD.FORM.AGENT_CATEGORY.LABEL') }}
                  <select v-model="agentCategory">
                      <option v-for="category in this.agentCategories" :key="category.name" :value="category.name">
                          {{ category.label }}
                      </option>
                  </select>
              </label>
          </div>
        <div class="medium-12 columns">
          <label :class="{ error: $v.agentEmail.$error }">
            {{ $t('AGENT_MGMT.ADD.FORM.EMAIL.LABEL') }}
            <input
              v-model.trim="agentEmail"
              type="text"
              :placeholder="$t('AGENT_MGMT.ADD.FORM.EMAIL.PLACEHOLDER')"
              @input="$v.agentEmail.$touch"
            />
          </label>
        </div>
        <div class="medium-12 columns" v-if="agentType === 'administrator' || agentType === 'manager' || agentType === 'agent'">
          <label>
            {{ $t('AGENT_MGMT.ADD.FORM.ADD_AGENTS_ON_INBOX.LABEL') }}
            <multiselect
                v-model="inboxes"
                :options="inboxesList"
                track-by="id"
                label="name"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :hide-selected="true"
                :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                :placeholder="$t('AGENT_MGMT.ADD.FORM.ADD_AGENTS_ON_INBOX.PLACEHOLDER')"
            >
            </multiselect>
          </label>
        </div>
        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-submit-button
              :disabled="
                $v.agentEmail.$invalid ||
                  $v.agentName.$invalid ||
                  uiFlags.isCreating
              "
              :button-text="$t('AGENT_MGMT.ADD.FORM.SUBMIT')"
              :loading="uiFlags.isCreating"
            />
            <button class="button clear" @click.prevent="onClose">
              {{ $t('AGENT_MGMT.ADD.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </woot-modal>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

export default {
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      agentName: '',
      agentEmail: '',
      agentType: 'agent',
      vertical: 'bottom',
      horizontal: 'center',
      show: true,
      inboxes: [],
      agentCategory: '',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'agents/getUIFlags',
      inboxesList: 'inboxes/getInboxes',
      currentUserRole: 'getCurrentRole',
    }),

      roles() {
          if(this.currentUserRole === 'manager') {
              return [{
                  name: 'agent',
                  label: this.$t('AGENT_MGMT.AGENT_TYPES.AGENT'),
              }]
          }else {
            return  [
              {
                  name: 'administrator',
                      label: this.$t('AGENT_MGMT.AGENT_TYPES.ADMINISTRATOR'),
              },
              {
                  name: 'agent',
                      label: this.$t('AGENT_MGMT.AGENT_TYPES.AGENT'),
              },
              {
                  name: 'manager',
                      label: this.$t('AGENT_MGMT.AGENT_TYPES.MANAGER'),
              },
                  ]
          }
      },
      agentCategories() {
        return  [
            {
                name: 'adcomp',
                label: this.$t('AGENT_MGMT.AGENT_CATEGORY.ADCOM'),
            },
            {
                name: 'city',
                label: this.$t('AGENT_MGMT.AGENT_CATEGORY.CITY'),
            }
            ]
      }
  },
  validations: {
    agentName: {
      required,
      minLength: minLength(1),
    },
    agentEmail: {
      required,
      email,
    },
    agentType: {
      required,
    },
  },

  async mounted() {
    await this.$store.dispatch('inboxes/get');
  },

  methods: {
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
    async addAgent() {
      try {
        const selectedInboxes = this.inboxes?.map(x => x.id);
        await this.$store.dispatch('agents/create', {
          name: this.agentName,
          email: this.agentEmail,
          role: this.agentType,
          category: this.agentCategory,
          inboxes: selectedInboxes,
        });
        this.showAlert(this.$t('AGENT_MGMT.ADD.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        const {
          response: { data: { error: errorResponse = '' } = {} } = {},
        } = error;
        let errorMessage = '';
        if (error.response.status === 422) {
          errorMessage = this.$t('AGENT_MGMT.ADD.API.EXIST_MESSAGE');
        } else {
          errorMessage = this.$t('AGENT_MGMT.ADD.API.ERROR_MESSAGE');
        }
        this.showAlert(errorResponse || errorMessage);
      }
    },
  },
};
</script>
