<template>
    <woot-modal :show.sync="show" :on-close="onClose">
        <div class="column content-box">
            <woot-modal-header
                    :header-title="$t('FEEDBACK_MESSAGE.ADD.TITLE')"
                    :header-content="$t('FEEDBACK_MESSAGE.ADD.DESC')"
            />
            <form class="row" @submit.prevent="addIframeCreator()">
                <div class="medium-12 columns">
                    <label :class="{ error: $v.feedbackName.$error }">
                        {{ $t('FEEDBACK_MESSAGE.ADD.FORM.NAME.LABEL') }}
                        <input
                                v-model.trim="feedbackName"
                                type="text"
                                :placeholder="$t('FEEDBACK_MESSAGE.ADD.FORM.NAME.PLACEHOLDER')"
                                @input="$v.feedbackName.$touch"
                        />
                    </label>
                </div>
                <div class="medium-12 columns">
                    <label :class="{ error: $v.feedbackUrl.$error }">
                        {{ $t('FEEDBACK_MESSAGE.ADD.FORM.ENDPOINT.LABEL') }}
                        <input
                                v-model.trim="feedbackUrl"
                                type="text"
                                :placeholder="$t('FEEDBACK_MESSAGE.ADD.FORM.ENDPOINT.PLACEHOLDER')"
                                @input="$v.feedbackUrl.$touch"
                        />
                    </label>
                </div>
                <div class="medium-12 columns">
                    <label>
                        {{ $t('FEEDBACK_MESSAGE.ADD.FORM.AGENT.LABEL') }}
                        <select v-model="agent">
                            <option v-for="ag in agentsList" :key="ag.id" :value="ag.id">
                                {{ ag.name }}
                            </option>
                        </select>
                    </label>
                </div>
                <div class="modal-footer">
                    <div class="medium-12 columns">
                        <woot-submit-button
                                :disabled="
                $v.feedbackName.$invalid ||
                  $v.feedbackUrl.$invalid ||
                  $v.agent.$invalid ||
                  uiFlags.isCreating
              "
                                :button-text="$t('FEEDBACK_MESSAGE.ADD.FORM.SUBMIT')"
                                :loading="uiFlags.isCreating"
                        />
                        <button class="button clear" @click.prevent="onClose">
                            {{ $t('FEEDBACK_MESSAGE.ADD.CANCEL_BUTTON_TEXT') }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </woot-modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

export default {
    props: {
        onClose: {
            type: Function,
            default: () => {},
        },
    },
    data() {
        return {
            feedbackName: '',
            feedbackUrl: '',
            vertical: 'bottom',
            horizontal: 'center',
            show: true,
            agent: {},
        };
    },
    computed: {
        ...mapGetters({
            uiFlags: 'agents/getUIFlags',
            agentsList: 'agents/getAgents',
            currentUserRole: 'getCurrentRole',
        }),
    },
    validations: {
        feedbackName: {
            required,
            minLength: minLength(3),
        },
        feedbackUrl: {
            required,
        },
        agent: {
            required,
        }
    },
    async mounted() {
        await this.$store.dispatch('agents/get');
    },
    methods: {
        showAlert(message) {
            bus.$emit('newToastMessage', message);
        },
        async addIframeCreator() {
            try {
                await this.$store.dispatch('feedbacks/create', {
                    name: this.feedbackName,
                    endpoint: this.feedbackUrl,
                    user_id: this.agent,
                });
                this.showAlert(this.$t('FEEDBACK_MESSAGE.ADD.API.SUCCESS_MESSAGE'));
                this.onClose();
            } catch (error) {
                const {
                    response: { data: { error: errorResponse = '' } = {} } = {},
                } = error;
                let errorMessage = '';
                if (error.response?.status === 422) {
                    errorMessage = this.$t('FEEDBACK_MESSAGE.ADD.API.EXIST_MESSAGE');
                } else {
                    errorMessage = this.$t('FEEDBACK_MESSAGE.ADD.API.ERROR_MESSAGE');
                }
                this.showAlert(errorResponse || errorMessage);
            }
        },
    },
};
</script>
