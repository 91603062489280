var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wizard-body columns content-box small-9"},[_c('div',{staticClass:"medium-8 columns"},[_c('label',[_vm._v("\n            "+_vm._s(_vm.$t('INBOX_MGMT.ADD.SELECT_CHANNEL_CATEGORY.LABEL'))+"\n            "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.inboxCategory),expression:"inboxCategory"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.inboxCategory=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"","disabled":"","hidden":""}},[_vm._v("\n                    "+_vm._s(_vm.$t(
                        'INBOX_MGMT.ADD.SELECT_CHANNEL_CATEGORY.PLACEHOLDER'
                    ))+"\n                ")]),_vm._v(" "),_c('option',{domProps:{"value":'adcomp'}},[_vm._v("\n                    "+_vm._s(_vm.$t(
                        'INBOX_MGMT.ADD.SELECT_CHANNEL_CATEGORY.ADCOMP'
                    ))+"\n                ")]),_vm._v(" "),_c('option',{domProps:{"value":'adcomp_sales'}},[_vm._v("\n                    "+_vm._s(_vm.$t(
                        'INBOX_MGMT.ADD.SELECT_CHANNEL_CATEGORY.ADCOMP_SALES'
                    ))+"\n                ")]),_vm._v(" "),_c('option',{domProps:{"value":'city'}},[_vm._v("\n                    "+_vm._s(_vm.$t(
                        'INBOX_MGMT.ADD.SELECT_CHANNEL_CATEGORY.CITY'
                    ))+"\n                ")])])])]),_vm._v(" "),_c('div',{staticClass:"login-init full-height text-center"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.requestAuthorization($event)}}},[_c('woot-submit-button',{attrs:{"icon":"brand-twitter","button-text":"Sign in with Twitter","type":"submit","loading":_vm.isRequestingAuthorization}})],1),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t('INBOX_MGMT.ADD.TWITTER.HELP')))])])])}
var staticRenderFns = []

export { render, staticRenderFns }