<template>
  <div class="column content-box">
    <woot-button
      color-scheme="success"
      class-names="button--fixed-right-top"
      icon="add-circle"
      @click="openAddPopup()"
    >
      {{ $t('IFRAME_CREATOR.ADD.TITLE') }}
    </woot-button>

    <!-- List Agents -->
    <div class="row">
      <div class="small-8 columns with-right-space ">
        <woot-loading-state
          v-if="uiFlags.isFetching"
          :message="$t('IFRAME_CREATOR.LOADING')"
        />
        <div v-else>
          <p v-if="!iframeList.length">
            {{ $t('IFRAME_CREATOR.LIST.404') }}
          </p>
          <table v-else class="woot-table">
            <thead>
              <th
                v-for="tableHeader in $t('IFRAME_CREATOR.LIST.TABLE_HEADER')"
                :key="tableHeader"
                class="item"
              >
                {{ tableHeader }}
              </th>
            </thead>
            <tbody>
              <tr v-for="(iframe, index) in iframeList" :key="iframe.id">
                <!-- Gravtar Image -->
                <td class="label-title">
                  <span class="text-truncate">
                    {{ iframe.inbox_name }}
                  </span>
                </td>
                <!-- Agent Name + Email -->
                <td class="fixed-width">
                  <span class="text-truncate">
                    {{ iframe.title }}
                  </span>
                </td>
                <td class="fixed-width">
                  <span class="text-truncate">
                    {{ truncateText(iframe.url, 40) }}
                  </span>
                </td>
                <!-- Agent Role + Verification Status -->
                <td class="fixed-width">
                  <span class="text-truncate">
                    {{ iframe.status }}
                  </span>
                </td>
                <!-- Actions -->
                <td class="fixed-width">
                  <div class="button-wrapper">
                    <woot-button
                      v-tooltip.top="$t('IFRAME_CREATOR.EDIT.BUTTON_TEXT')"
                      variant="smooth"
                      size="tiny"
                      color-scheme="secondary"
                      icon="edit"
                      class-names="grey-btn"
                      @click="openEditPopup(iframe)"
                    />
                    <woot-button
                      v-tooltip.top="$t('IFRAME_CREATOR.DELETE.BUTTON_TEXT')"
                      variant="smooth"
                      color-scheme="alert"
                      size="tiny"
                      icon="dismiss-circle"
                      class-names="grey-btn"
                      :is-loading="loading[iframe.id]"
                      @click="openDeletePopup(iframe, index)"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="small-4 columns">
        <span
          v-dompurify-html="
            useInstallationName(
              $t('IFRAME_CREATOR.SIDEBAR_TXT'),
              globalConfig.installationName
            )
          "
        />
      </div>
    </div>
    <!-- Add Agent -->
    <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
      <add-iframe-creator :on-close="hideAddPopup" />
    </woot-modal>
    <!-- Edit Agent -->
    <woot-modal :show.sync="showEditPopup" :on-close="hideEditPopup">
      <edit-iframe-creator
        v-if="showEditPopup"
        :id="currentIframe.id"
        :title="currentIframe.title"
        :url="currentIframe.url"
        :status="currentIframe.status"
        :inbox-id="currentIframe.inbox_id"
        :on-close="hideEditPopup"
      />
    </woot-modal>
    <!-- Delete Agent -->
    <woot-delete-modal
      :show.sync="showDeletePopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('IFRAME_CREATOR.DELETE.CONFIRM.TITLE')"
      :message="$t('IFRAME_CREATOR.DELETE.CONFIRM.MESSAGE')"
      :message-value="deleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import AddIframeCreator from './AddIframeCreator.vue';
import EditIframeCreator from './EditIframeCreator.vue';
export default {
  components: {
    AddIframeCreator,
    EditIframeCreator,
  },
  mixins: [globalConfigMixin],
  computed: {
    ...mapGetters({
      iframeList: 'iframeCreators/getIframeCreators',
      uiFlags: 'iframeCreators/getUIFlags',
      globalConfig: 'globalConfig/get',
    }),
    deleteMessage() {
      return ` ${this.currentIframe.title}?`;
    },
    deleteConfirmText() {
      return `${this.$t('IFRAME_CREATOR.DELETE.CONFIRM.YES')} ${
        this.currentIframe.title
      }`;
    },
    deleteRejectText() {
      return `${this.$t('IFRAME_CREATOR.DELETE.CONFIRM.NO')} ${
        this.currentIframe.title
      }`;
    },
  },
  data() {
    return {
      loading: {},
      showAddPopup: false,
      showDeletePopup: false,
      showEditPopup: false,
      iframeCreatorAPI: {
        message: '',
      },
      currentIframe: {},
    };
  },
  mounted() {
    this.$store.dispatch('iframeCreators/get');
  },
  methods: {
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },
    // Delete Function
    openDeletePopup(iframe) {
      this.showDeletePopup = true;
      this.currentIframe = iframe;
    },
    closeDeletePopup() {
      this.showDeletePopup = false;
    },
    confirmDeletion() {
      this.loading[this.currentIframe.id] = true;
      this.closeDeletePopup();
      this.deleteIframe(this.currentIframe.id);
    },
    // Edit Function
    openEditPopup(iframe) {
      this.showEditPopup = true;
      this.currentIframe = iframe;
    },
    async deleteIframe(id) {
      try {
        await this.$store.dispatch('iframeCreators/delete', id);
        this.showAlert(this.$t('IFRAME_CREATOR.DELETE.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('IFRAME_CREATOR.DELETE.API.ERROR_MESSAGE'));
      }
    },
    // Show SnackBar
    showAlert(message) {
      // Reset loading, current selected iframeCreator
      this.loading[this.currentIframe.id] = false;
      this.currentIframe = {};
      // Show message
      this.iframeCreatorAPI.message = message;
      bus.$emit('newToastMessage', message);
    },
    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
      }
      return text;
    },
  },
};
</script>
<style scoped lang="scss">
.fixed-width {
  width: 150px; /* You can adjust the width to your desired value */
}
</style>
