var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wizard-body small-9 columns"},[_c('page-header',{attrs:{"header-title":_vm.$t('INBOX_MGMT.ADD.TELEGRAM_CHANNEL.TITLE'),"header-content":_vm.$t('INBOX_MGMT.ADD.TELEGRAM_CHANNEL.DESC')}}),_vm._v(" "),_c('form',{staticClass:"row",on:{"submit":function($event){$event.preventDefault();return _vm.createChannel()}}},[_c('div',{staticClass:"medium-8 columns"},[_c('label',[_vm._v("\n              "+_vm._s(_vm.$t('INBOX_MGMT.ADD.SELECT_CHANNEL_CATEGORY.LABEL'))+"\n              "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.inboxCategory),expression:"inboxCategory"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.inboxCategory=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"","disabled":"","hidden":""}},[_vm._v("\n                      "+_vm._s(_vm.$t(
                          'INBOX_MGMT.ADD.SELECT_CHANNEL_CATEGORY.PLACEHOLDER'
                      ))+"\n                  ")]),_vm._v(" "),_c('option',{domProps:{"value":'adcomp'}},[_vm._v("\n                      "+_vm._s(_vm.$t(
                          'INBOX_MGMT.ADD.SELECT_CHANNEL_CATEGORY.ADCOMP'
                      ))+"\n                  ")]),_vm._v(" "),_c('option',{domProps:{"value":'adcomp_sales'}},[_vm._v("\n                      "+_vm._s(_vm.$t(
                          'INBOX_MGMT.ADD.SELECT_CHANNEL_CATEGORY.ADCOMP_SALES'
                      ))+"\n                  ")]),_vm._v(" "),_c('option',{domProps:{"value":'city'}},[_vm._v("\n                      "+_vm._s(_vm.$t(
                          'INBOX_MGMT.ADD.SELECT_CHANNEL_CATEGORY.CITY'
                      ))+"\n                  ")])])])]),_vm._v(" "),_c('div',{staticClass:"medium-8 columns"},[_c('label',{class:{ error: _vm.$v.botToken.$error }},[_vm._v("\n        "+_vm._s(_vm.$t('INBOX_MGMT.ADD.TELEGRAM_CHANNEL.BOT_TOKEN.LABEL'))+"\n        "),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.botToken),expression:"botToken",modifiers:{"trim":true}}],attrs:{"type":"text","placeholder":_vm.$t('INBOX_MGMT.ADD.TELEGRAM_CHANNEL.BOT_TOKEN.PLACEHOLDER')},domProps:{"value":(_vm.botToken)},on:{"blur":[_vm.$v.botToken.$touch,function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing){ return; }_vm.botToken=$event.target.value.trim()}}})]),_vm._v(" "),_c('p',{staticClass:"help-text"},[_vm._v("\n        "+_vm._s(_vm.$t('INBOX_MGMT.ADD.TELEGRAM_CHANNEL.BOT_TOKEN.SUBTITLE'))+"\n      ")])]),_vm._v(" "),_c('div',{staticClass:"medium-12 columns"},[_c('woot-submit-button',{attrs:{"loading":_vm.uiFlags.isCreating,"button-text":_vm.$t('INBOX_MGMT.ADD.TELEGRAM_CHANNEL.SUBMIT_BUTTON')}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }